"use strict";

/**
 * Property of OSF GLOBAL SERVICES INC. , an OSF Digital company. OSF remains the sole owner of all right, title and interest in the software. Do not copy, sell, reverse engineer or otherwise attempt to derive or obtain information about the functioning, manufacture or operation therein.
 */

let Cleave = require("cleave.js").default;

/**
 * @function updatePaymentInformation
 * @description Update payment details summary based on payment method
 * @param {Object} order - checkout model to use as basis of new truth
 */
function updatePaymentInformation(order) {
    // update payment details
    let $paymentSummary = $(".payment-details");
    let htmlToAppend = "";

    if (order && order.billing.payment && order.billing.payment.selectedPaymentInstruments && order.billing.payment.selectedPaymentInstruments.length > 0) {
        order.billing.payment.selectedPaymentInstruments.forEach(function (selectedPaymentInstrument) {
            if (selectedPaymentInstrument.paymentMethod === window.mercado_pago.creditCard) {
                htmlToAppend += "<span>" + order.resources.cardType + " "
                    + selectedPaymentInstrument.type
                    + "</span><div>"
                    + selectedPaymentInstrument.maskedCreditCardNumber
                    + "</div><div><span>"
                    + order.resources.cardEnding + " "
                    + selectedPaymentInstrument.expirationMonth
                    + "/" + selectedPaymentInstrument.expirationYear
                    + "</span></div>";
            } else if (selectedPaymentInstrument.paymentMethod === window.mercado_pago.mercadoPagoCard) {
                const resourceMessages = $(".js-mp-available-payment-methods").data("mpResourceMessages");
                const preferences = $(".js-mp-available-payment-methods").data("mpPreferences");
                const showInstallments = preferences.enableInstallments === true;
                const installmentsElement = $("#installments");
                const installments = installmentsElement && installmentsElement.find(":selected").val();

                let paymentMethods = $(".js-mp-available-payment-methods").data("mpAvailablePaymentMethods");
                let paymentInstrumentType = selectedPaymentInstrument.type;
                let paymentMethod = paymentMethods.find(function (method) { return paymentInstrumentType === method.id; });
                let paymentMethodName = paymentMethod ? paymentMethod.name : paymentInstrumentType;

                htmlToAppend += "<span>"
                    + selectedPaymentInstrument.paymentMethod + " "
                    + paymentMethodName
                    + "</span><br/>";
                const installmentsMessageToAppend = "<span>"
                    + resourceMessages.installmentsInfo.replace("{0}", installments || "1")
                    + "</span><br/>";
                if (showInstallments) {
                    htmlToAppend += installmentsMessageToAppend;
                }
            } else if (selectedPaymentInstrument.paymentMethod === window.mercado_pago.giftCertificate) {
                htmlToAppend += "<span>"
                    + order.resources.giftCertificate
                    + "</span><br/>";
            }
        });
    }

    $paymentSummary.empty().append(htmlToAppend);
}

/**
 * @function handlePaymentOptionChange
 * @description Handle payment option change
 */
function handlePaymentOptionChange() {
    let $activeTab = $(this);
    let activeTabId = $activeTab.attr("href");
    let $paymentInformation = $(".payment-information");
    let isNewPayment = $(".user-payment-instruments").hasClass("checkout-hidden");

    $(".payment-options [role=tab]").each(function (i, tab) {
        let otherTab = $(tab);
        let otherTabId = otherTab.attr("href");

        $(otherTabId).find("input, select").prop("disabled", otherTabId !== activeTabId);
    });

    if (activeTabId === "#credit-card-content") {
        // Restore
        $paymentInformation.data("is-new-payment", isNewPayment);
    } else {
        // Prevent rejection during payment submit
        $paymentInformation.data("is-new-payment", true);
    }
}

/**
* Event handler for selecting a billing address
* @returns {void}
*/
function selectBillingAddress() {
    $(".payment-form .addressSelector").on("change", function () {
        let form = $(this).parents("form")[0];
        let selectedOption = $("option:selected", this);
        let optionID = selectedOption[0].value;

        if (optionID === "new") {
            // Show Address
            $(form).attr("data-address-mode", "new");
        } else {
            // Hide Address
            $(form).attr("data-address-mode", "shipment");
        }

        // Copy fields
        let attrs = selectedOption.data();
        let element;

        Object.keys(attrs).forEach(function (attr) {
            element = attr === "countryCode" ? "country" : attr;
            if (element === "cardNumber") {
                $(".cardNumber").data("cleave").setRawValue(attrs[attr]);
            } else if (element === "mercadoPagoCardNumber") {
                $(".mercadoPagoCardNumber").data("cleave").setRawValue(attrs[attr]);
            } else if (element === "stateCode") {
                // Change both the default stateCode input and the Specific country stateCode input to the selected value
                $("[name$=" + element + "]", form).val(attrs[attr]);
                $("[name$=" + element + attrs["countryCode"] +"]", form).val(attrs[attr]);
            } else {
                $("[name$=" + element + "]", form).val(attrs[attr]);

                // Trigger a manual change event to the country select so that the state select gets updated too.
                if (element === "country") {
                    $("[name$=" + element + "]", form).trigger("change");
                }
            }
        });
    });
}

/**
* Checks the credit card number for both regular and Mercado Pago payments
* @param {string} cardFieldSelector - the class of the card number field
* @param {string} cardTypeSelector - the id of the card type field
*/
function checkCreditCard(cardFieldSelector, cardTypeSelector) {
    let cleave = new Cleave(cardFieldSelector, {
        creditCard: true,
        onCreditCardTypeChanged: function (type) {
            let creditCardTypes = {
                visa: "Visa",
                mastercard: "Master Card",
                amex: "Amex",
                discover: "Discover",
                unknown: "Unknown"
            };

            let cardType = creditCardTypes[Object.keys(creditCardTypes).indexOf(type) > -1
                ? type
                : "unknown"];
            $(cardTypeSelector).val(cardType);
            $(".card-number-wrapper").attr("data-type", type);
            if (type === "visa" || type === "mastercard" || type === "discover") {
                $("#securityCode").attr("maxlength", 3);
            } else {
                $("#securityCode").attr("maxlength", 4);
            }
        }
    });

    $(cardFieldSelector).data("cleave", cleave);
}

/**
* Handles the credit card number for both regular and Mercado Pago payments
* @param {string} cardNumber - the class of the card number field
* @param {string} cardType - the id of the card type field
*/
function handleCreditCardNumber() {
    checkCreditCard(".cardNumber", "#cardType");
    if ($(".mercadoPagoCardNumber").length > 0) {
        checkCreditCard(".mercadoPagoCardNumber", "#cardType");
    }
}

/**
 * @function useSameMailPhoneAsAddress
 * @description fill user information for payment data
 */
function useSameMailPhoneAsAddress() {
    let fillSameFields = function () {
        $(".js-mp-phone").val($("#phoneNumber").val());
        if ($("#email")) {
            $(".js-mp-email").val($("#email").val());
        } else if ($(".customer-summary-email")) {
            $(".js-mp-email").val($(".customer-summary-email")[0].innerText);
        }
    };

    $("#useSameMailPhoneAsAddress").change(function () {
        $(".js-mail-phone-container").toggleClass("checkout-hidden", this.checked);

        if (this.checked) {
            fillSameFields();
            $("#phoneNumber").on("change.usesame", fillSameFields);
            $("#email").on("change.usesame", fillSameFields);
        } else {
            $(".js-mp-phone").val("");
            $(".js-mp-email").val("");
            $("#phoneNumber").off("change.usesame");
            $("#email").off("change.usesame");
        }
    });
}

/**
 * @function changePaymentOption
 * @description Change payment option
 */
/**
* Handles change of payment option
* @param {Object} event - The event object
*/
function changePaymentOption() {
    $(".payment-options [role=tab]").on("click", handlePaymentOptionChange); // By click
}

/**
 * @function initPaymentOption
 * @description Initiate payment option
 */
/**
* Triggers the click event for the enabled tab and calls the handlePaymentOptionChange method
*/
function initPaymentOption() {
    // Initial
    $(".payment-options [role=tab].enabled").trigger("click");
    $("body").on("checkout:updateCheckoutView", function (e, data) {
        window.order = data.order;
        updatePaymentInformation(data.order);
    });
}

module.exports = {
    methods: {
        updatePaymentInformation: updatePaymentInformation,
        handlePaymentOptionChange: handlePaymentOptionChange,
        selectBillingAddress: selectBillingAddress,
        handleCreditCardNumber: handleCreditCardNumber,
        changePaymentOption: changePaymentOption,
        initPaymentOption: initPaymentOption
    },
    useSameMailPhoneAsAddress: useSameMailPhoneAsAddress,
    selectBillingAddress: selectBillingAddress
};
