"use strict";

let mercadoBilling = require("./mercadoPago/mercadoBilling");

/**
 * Property of OSF GLOBAL SERVICES INC. , an OSF Digital company. OSF remains the sole owner of all right, title and interest in the software. Do not copy, sell, reverse engineer or otherwise attempt to derive or obtain information about the functioning, manufacture or operation therein.
 */

(function ($) {
    /**
     * hide states/federal units based on country
     * @param {*} shippingStage stage
     */
    function checkTheCountry(shippingStage) {
        var country;
        if (shippingStage) {
            country = $(".single-shipping .shipping-or-billing-country-select");
        } else {
            country = $("#billingCountry");
        }
        if (String(country.val()) === "BR") {
            if (shippingStage) {
                $(".hide-default-states").hide();
                $(".showForAR").hide();
                $(".showForCL").hide();
                $(".showForPE").hide();
                $(".showForCO").hide();
                $(".showForBR").show();
            } else {
                $(".billing-showForBR").show();
                $(".billing-hide-default-states").hide();
                $(".billing-showForAR").hide();
                $(".billing-showForCL").hide();
                $(".billing-showForPE").hide();
                $(".billing-showForCO").hide();
            }
        }
        if (String(country.val()) === "CL") {
            if (shippingStage) {
                $(".showForCL").show();
                $(".hide-default-states").hide();
                $(".showForBR").hide();
                $(".showForAR").hide();
                $(".showForPE").hide();
                $(".showForCO").hide();
            } else {
                $(".billing-showForCL").show();
                $(".billing-hide-default-states").hide();
                $(".billing-showForBR").hide();
                $(".billing-showForAR").hide();
                $(".billing-showForPE").hide();
                $(".billing-showForCO").hide();
            }
        }
        if (String(country.val()) === "AR") {
            if (shippingStage) {
                $(".showForAR").show();
                $(".hide-default-states").hide();
                $(".showForBR").hide();
                $(".showForCL").hide();
                $(".showForPE").hide();
                $(".showForCO").hide();
            } else {
                $(".billing-showForAR").show();
                $(".billing-hide-default-states").hide();
                $(".billing-showForBR").hide();
                $(".billing-showForCL").hide();
                $(".billing-showForPE").hide();
                $(".billing-showForCO").hide();
            }
        }
        if (String(country.val()) === "PE") {
            if (shippingStage) {
                $(".showForPE").show();
                $(".hide-default-states").hide();
                $(".showForBR").hide();
                $(".showForAR").hide();
                $(".showForCL").hide();
                $(".showForCO").hide();
            } else {
                $(".billing-showForPE").show();
                $(".billing-hide-default-states").hide();
                $(".billing-showForBR").hide();
                $(".billing-showForAR").hide();
                $(".billing-showForCL").hide();
                $(".billing-showForCO").hide();
            }
        }
        if (String(country.val()) === "US") {
            if (shippingStage) {
                $(".showForPE").hide();
                $(".hide-default-states").show();
                $(".showForBR").hide();
                $(".showForAR").hide();
                $(".showForCL").hide();
                $(".showForCO").hide();
            } else {
                $(".billing-showForPE").hide();
                $(".billing-hide-default-states").show();
                $(".billing-showForBR").hide();
                $(".billing-showForAR").hide();
                $(".billing-showForCL").hide();
                $(".billing-showForCO").hide();
            }
        }
        if (String(country.val()) === "CO") {
            if (shippingStage) {
                $(".showForCO").show();
                $(".hide-default-states").hide();
                $(".showForBR").hide();
                $(".showForAR").hide();
                $(".showForCL").hide();
                $(".showForPE").hide();
            } else {
                $(".billing-showForCO").show();
                $(".billing-hide-default-states").hide();
                $(".billing-showForBR").hide();
                $(".billing-showForAR").hide();
                $(".billing-showForCL").hide();
                $(".billing-showForPE").hide();
            }
        }
    }
    /**
     * Replace Billing Form
     */
    function replaceBillingForm() {
        $.ajax({
            url: $(".get-address-form").data("url"),
            method: "GET",
            data: {
                stage: "payment"
            },
            success: function (data) {
                var shippingStage = false;
                $(".billing-address").html(data);
                $(".billing-hide-default-states").show();
                $(".billing-showForAR").hide();
                $(".billing-showForCL").hide();
                $(".billing-showForPE").hide();
                $(".billing-showForBR").hide();
                $(".billing-showForCO").hide();
                $(document).on("change", ".dwfrm_billing_addressFields_country", function () { checkTheCountry(shippingStage, $(this)); });
                checkTheCountry(shippingStage);
            }
        });
    }

    /**
     * Replace Shipping Form
     */
    function replaceShippingForm() {
        $.ajax({
            url: $(".get-address-form").data("url"),
            method: "GET",
            data: {
                stage: "shipping"
            },
            success: function (data) {
                var shippingStage = true;
                $(".shipping-address-block").html(data);
                $(".hide-default-states").show();
                $(".showForAR").hide();
                $(".showForCL").hide();
                $(".showForPE").hide();
                $(".showForBR").hide();
                $(".showForCO").hide();
                $(document).on("change", ".dwfrm_shipping_shippingAddress_addressFields_country", function () { checkTheCountry(shippingStage); });
                checkTheCountry(shippingStage);
                // Removed scripts that hide the shipment selector feature
                // $(".shipping-form").attr("data-address-mode", "details");
                // $(".shipment-selector-block").remove();
            }
        });
    }
    var checkoutDataStage = $(".data-checkout-stage");
    if (checkoutDataStage.length > 0) {
        if (checkoutDataStage[0].dataset.checkoutStage === "shipping") {
            replaceShippingForm();
        } else if (checkoutDataStage[0].dataset.checkoutStage === "payment") {
            replaceBillingForm();
        } else if (checkoutDataStage[0].dataset.checkoutStage === "placeOrder") { // Add this condition to replace both forms in the placeOrder stage to avoid custom fields not being replaced
            replaceShippingForm();
            replaceBillingForm();
        }
    }

    $(".submit-customer").on("click", function () {
        replaceShippingForm();
    });

    $("body").on("checkout:updateCheckoutView", function (e, data) {
        replaceBillingForm();

        /*  This timeout is necessary because the updatePaymentInformation on
            Base cartridge would overwrite the changes made from the one in Mercado Pago otherwise*/
        setTimeout(() => {
            mercadoBilling.methods.updatePaymentInformation(data.order);

            // Set the value of the country selector to the first option
            $(".shipping-or-billing-country-select").prop("selectedIndex", 0).change();
        });

    });

    // When the form of the billing/shipping addresses are reset, the country selector should value should be set back by code.
    $(document).on("click", ".btn-show-details, .btn-add-new", function () {
        $(".shipping-or-billing-country-select").prop("selectedIndex", 0).change();
    });
}(window.jQuery));
